import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';

import { AppConfig } from '../../_configs/config';
import { ApiResponseDto, LoginApiResponse, UserLoginDto } from '../../_models/_index';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    constructor(private http: HttpClient,
        private cookieService: CookieService) { }

    login(user: UserLoginDto) {
        console.log(user);

        // The response type is set to <ApiResponseDto> but than is convert to any (res: any)
        return this.http.post<LoginApiResponse>(AppConfig.url + '/auth/generate-token', user)
            .pipe(map((response: LoginApiResponse) => {
                if (response.data && response.data.token) {

                    const token =  JSON.stringify({ token: response.data.token });
                    this.cookieService.set('currentUser',token, 0.25, "/", null!, false, 'Strict');

                    return response;
                }
                else {
                    return response;
                }
            }));

        // //
        // // Test
        // //
        // console.log('Before login() return...');
        // const response = {
        //     data:
        //     {
        //         token: "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6IjkyOGFjNWQ3LTAxOGYtMTAwMC1hN2E2LTYwNTVkMDJjMzU4MSIsInN1YiI6Iml2YW5vdjFAdGVzdC5jb20iLCJpYXQiOjE3MTYzMTcyODEsImV4cCI6MTcxNjMyMDg4MX0.bt0_YfzcVIs1Xaj7MPFKE-rMF6EactjaZsT7U64zcCU"
        //     },
        //     errors: {}
        // } as LoginApiResponse;
        // this.cookieService.set('currentUser',
        //     JSON.stringify({ username: user.username, token: response.data.token }), 0.25, "/", null!, false, 'Strict');
        // return of(response);
    }

    logout() {
        this.cookieService.delete("currentUser", "/");
    }

}