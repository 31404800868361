import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConfig } from '../../_configs/_index';
import { ApiResponseDto, User } from '../../_models/_index';

@Injectable({
	providedIn: 'root'
})
export class RegistrationService {

	constructor(private http: HttpClient) { }

	createAccount(user: User) {

		return this.http.post<ApiResponseDto>(AppConfig.url + '/user/create-account', user)
			.pipe(map((response: any) => {
				if (response) {
					return response;
				}
				else {
					return response;
				}
		}));
	}
}