import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConfig } from '../../_configs/_index';
import { ApiResponseDto, UserLoginDto } from '../../_models/_index';

@Injectable({
	providedIn: 'root'
})
export class ResetPasswordService {

	constructor(private http: HttpClient) { }

	requestResetPassword(username: string) {

		return this.http.get<ApiResponseDto>(AppConfig.url + '/user/request-reset-password/' + username)
			.pipe(map((response: any) => {
				if (response) {
					return response;
				}
				else {
					return response;
				}
			}));

	}

	resetPassword(token: string, userLoginDto: UserLoginDto) {

		return this.http.post<ApiResponseDto>(AppConfig.url + '/user/reset-password/' + token, userLoginDto)
			.pipe(map((res: any) => {
				if (res) {
					return res;
				}
				else {
					return res;
				}
			}));

	}
	
}