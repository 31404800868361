import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConfig } from '../../_configs/_index';
import { ApiResponseDto } from '../../_models/_index';

@Injectable({
	providedIn: 'root'
})
export class VerificationService {

	constructor(private http: HttpClient) { }

	verifyAccount(email: string) {

		return this.http.get<ApiResponseDto>(AppConfig.url + '/user/verify-account/' + email)
			.pipe(map((response: any) => {
				if (response) {
					return response;
				}
				else {
					return response;
				}
			}));

	}
	
}