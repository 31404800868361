import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private isloggedInSource = new BehaviorSubject<boolean>(false);
    currentIsloggedIn = this.isloggedInSource.asObservable();

    // Preloader
    private isActivePreloaderSource = new BehaviorSubject<boolean>(false);
    currentIsActivePreloader = this.isActivePreloaderSource.asObservable();

    private isSessionExpiredSource = new BehaviorSubject<boolean>(false);
    currentIsSessionExpiredSource = this.isSessionExpiredSource.asObservable();

    constructor(private cookieService: CookieService) {
        if (this.cookieService.get('currentUser')) {
            this.changeLogginInStatus(true);
        }
        this.changeIsSessionExpired(false);
    }

    changeLogginInStatus(message: boolean) {
        this.isloggedInSource.next(message);
    }

    // Preloader - Change status
    changeActivePreloaderStatus(status: boolean) {
        this.isActivePreloaderSource.next(status);
    }

    changeIsSessionExpired(message: boolean) {
        this.isSessionExpiredSource.next(message);
    }

}